import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS
} from '../actions/auth'
import { PARTICIPANT_OWN, PARTICIPANT_CLEAR } from '../actions/participant'
import api, { setAuthToken } from '@/utils/api'

const state = {
  token: localStorage.getItem('auth-token') || '',
  id: localStorage.getItem('auth-id') || '',
  status: '',
  hasLoadedOnce: false
}

const getters = {
  isAuthenticated: (state) => !!state.token,
  authId: (state) => state.id
}
const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      api
        .post('participant/login', user)
        .then((resp) => {
          localStorage.setItem('auth-id', resp.data.userId)
          localStorage.setItem('auth-token', resp.data.id)
          setAuthToken(resp.data.id)
          commit(AUTH_SUCCESS, resp)
          dispatch(PARTICIPANT_OWN)
          resolve(resp)
        })
        .catch((err) => {
          commit(AUTH_ERROR, err)
          localStorage.removeItem('auth-id')
          localStorage.removeItem('auth-token')
          reject(err?.response?.data?.error || err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      api
        .post('participant/logout')
        .then((resp) => {
          commit(AUTH_LOGOUT_SUCCESS)
          commit(PARTICIPANT_CLEAR)
          resolve(resp)
        })
        .catch((err) => {
          commit(AUTH_LOGOUT_SUCCESS)
          commit(PARTICIPANT_CLEAR)
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.token = resp.data.id
    state.id = resp.data.userId
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT_SUCCESS]: (state) => {
    localStorage.removeItem('auth-token')
    localStorage.removeItem('auth-id')
    state.status = ''
    state.token = ''
    state.id = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
