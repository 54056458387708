<template>
  <div id="app">
    <router-view />
    <cookie-modal />
  </div>
</template>

<script>
import { PARTICIPANT_OWN } from '@/store/actions/participant'
import CookieModal from '@/components/CookieModal'
// import CreateAccountModal from '@/components/CreateAccountModal'
// import Snackbar from '@/components/Snackbar'
// import update from '@/mixins/update' // disabled for now
import fbConversion from '@/utils/sendFacebookEvent'
import config from '@/utils/config'
import { mapGetters } from 'vuex'

console.log(config.paymentProvider)

export default {
  name: 'App',
  components: {
    CookieModal
    // Snackbar,
    // CreateAccountModal
  },
  mixins: [], // removed update
  data: () => ({
    updateText: `Er is een update beschikbaar`,
    showCreateAccountModal: false,
    skipLandingPage: config.featureFlags.skipLandingPage
  }),
  computed: {
    ...mapGetters(['getParticipantProfile', 'isAuthenticated'])
  },
  watch: {
    $route: async function (to) {
      await fbConversion({
        event: 'PageView',
        user: this.getParticipantProfile,
        url: `${config.appUrl}${to.path}`
      })
    }
  },
  created: function () {
    if (this.isAuthenticated) {
      this.$store.dispatch(PARTICIPANT_OWN)
    }
  },
  mounted: function () {
    setTimeout(() => {
      if (!this.isAuthenticated) this.showCreateAccountModal = true
    }, 5000)
  },
  methods: {
    updateMe: function () {
      if (process.env.NODE_ENV === 'production') {
        this.refreshApp()
      }
    }
  },
  metaInfo() {
    return {
      title: 'Inviplay',
      meta: [
        {
          name: 'description',
          content:
            'Sporten met vrienden of andere liefhebbers. Kies je favoriete sporten en doe mee wanneer het jou uitkomt, of huur een sporthal of tennisbaan bij jou in de buurt'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/main.scss';
</style>
