import axios from 'axios'
import config from './config'

const instance = axios.create({
  baseURL: config.baseUrl + '/api/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const setAuthToken = (token) => {
  instance.defaults.headers.Authorization = token
}

const googleMapsApi = axios.create({
  baseURL: config.googleMapsBaseUrl,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const googleSearchLongAndLatOnMap = (searchAddress) => {
  return googleMapsApi
    .get('json', {
      params: {
        address: searchAddress,
        key: config.googleMapsApiKey
      }
    })
    .then((res) => res.data)
    .then((mapsResponse) => {
      if (
        mapsResponse.results.length &&
        mapsResponse.results.hasOwnProperty(0) // eslint-disable-line no-prototype-builtins
      ) {
        // Get the first response since location has been found
        const firstResult = mapsResponse.results[0]
        if (firstResult.geometry && firstResult.geometry.location) {
          const location = firstResult.geometry.location
          let city = null
          if (
            firstResult.address_components &&
            firstResult.address_components.length
          ) {
            city = firstResult.address_components[2].long_name
          }
          return { location, city }
        }
      }
    })
    .catch((err) =>
      console.warn(
        'OrganizeLocationMap, searchAddressOnMap(): Failed with error',
        err
      )
    )
}

export default instance
