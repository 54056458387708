import {
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE,
  GROUP_ERROR,
  GROUP_GET_ALL_SUCCESS,
  GROUP_GET_ALL,
  GROUP_GET_DETAILS_SUCCESS,
  GROUP_GET_DETAILS,
  GROUP_GET_EVENTS_SUCCESS,
  GROUP_GET_EVENTS,
  GROUP_GET_JOINED_SUCCESS,
  GROUP_GET_JOINED,
  GROUP_GET_LEAGUES_SUCCESS,
  GROUP_GET_LEAGUES,
  GROUP_GET_UPCOMING_EVENTDATES,
  GROUP_GET_UPCOMING_EVENTDATES_SUCCESS,
  GROUP_GET_TEAMS_SUCCESS,
  GROUP_GET_TEAMS,
  GROUP_JOIN_SUCCESS,
  GROUP_JOIN,
  GROUP_LEAVE_SUCCESS,
  GROUP_LEAVE,
  GROUP_REQUEST,
  GROUP_RESET_STATE,
  GROUP_SEARCH_CLEAR,
  GROUP_SEARCH_SUCCESS,
  GROUP_SEARCH,
  GROUP_UPDATE_MEMBER_SUCCESS,
  GROUP_UPDATE_MEMBER,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE,
  GROUP_UPLOAD_IMAGE_ERROR,
  GROUP_UPLOAD_IMAGE_SUCCESS,
  GROUP_UPLOAD_IMAGE,
  GROUP_GET_CREATED_OR_ADMIN,
  GROUP_GET_CREATED_OR_ADMIN_SUCCESS
} from '../actions/group'
import store from '../../store'
import api from '@/utils/api'
import '@/assets/js/photoRotationAdjust'
import isDefinedType from 'is-defined-type'
import config from '@/utils/config'
import moment from 'moment'
import { DateTime } from 'luxon'
import noImage from '@/assets/images/img_no-image.png'

const state = {
  allGroups: { data: [], totalCount: 0 },
  groupsCreatedOrAdmin: [],
  createdGroup: null,
  error: null,
  events: [],
  groupDetails: null,
  groupIsDeleted: false,
  groupsJoined: { data: [], totalCount: 0 },
  imageError: null,
  joinedGroup: null,
  leagues: [],
  loading: false,
  memberUpdated: null,
  newImage: null,
  search: {
    count: null,
    results: []
  },
  teams: [],
  upcomingEvents: [],
  updatedGroup: null
}

const getters = {
  newImage: (state) => state.newImage,
  adminOfGroups: (state) =>
    state.groupCreatedOrAdmin.data.filter(
      (group) => group.ownerId === parseInt(store.getters.authId)
    )
}

const actions = {
  [GROUP_CREATE]: async ({ commit, dispatch }, newGroup) => {
    commit(GROUP_REQUEST)
    const {
      activityId,
      city,
      description,
      eventTypeId,
      image,
      name,
      visibility
    } = newGroup
    const imageUploadSuccess = await dispatch(GROUP_UPLOAD_IMAGE, image)
    return api
      .post('group', {
        activityID: activityId,
        city,
        description,
        event_typeID: eventTypeId,
        image: imageUploadSuccess ? state.newImage : null,
        name,
        needsPermissionToAdd: visibility === 'needsPermissionToAdd',
        private: visibility === 'privateGroup'
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data) {
          commit(GROUP_CREATE_SUCCESS, resp.data)
        } else {
          commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
        }
      })
      .catch((err) => {
        commit(GROUP_ERROR, `Something went wrong: ${err}`)
      })
  },
  [GROUP_UPDATE]: async ({ commit, dispatch }, { groupId, group }) => {
    commit(GROUP_REQUEST)
    const {
      activityId,
      city,
      description,
      eventTypeId,
      image,
      name,
      visibility,
      admins
    } = group
    const imageUploadSuccess =
      image && (await dispatch(GROUP_UPLOAD_IMAGE, image))
    return api
      .patch(`group/${groupId}`, {
        ...(activityId && { activityID: activityId }),
        ...(city && { city }),
        ...(description && { description }),
        ...(eventTypeId && { event_typeID: eventTypeId }),
        ...(image && { image: imageUploadSuccess ? state.newImage : null }),
        ...(name && { name }),
        ...(admins && { admins }),
        ...(visibility !== null && {
          needsPermissionToAdd: visibility === 'needsPermissionToAdd',
          private: visibility === 'privateGroup'
        })
      })
      .then((resp) => {
        if (resp.status === 200 && resp.data) {
          commit(GROUP_UPDATE_SUCCESS, resp.data)
        } else {
          commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
        }
      })
      .catch((err) => {
        commit(GROUP_ERROR, `Something went wrong: ${err}`)
      })
  },
  [GROUP_UPLOAD_IMAGE]: async ({ commit }, newImage) => {
    let imageUploadResponse = null
    try {
      if (newImage) {
        const adjustBlob = await window.photoRotationAdjust(newImage)
        const formData = new FormData()
        formData.append('file', adjustBlob)
        imageUploadResponse = await api.post(
          `attachments/${config.storageContainer}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        commit(GROUP_UPLOAD_IMAGE_SUCCESS, imageUploadResponse)
        return true
      } else {
        commit(GROUP_UPLOAD_IMAGE_ERROR, 'Something went wrong: No new image')
        return false
      }
    } catch (err) {
      commit(GROUP_UPLOAD_IMAGE_ERROR, `Something went wrong: ${err}`)
      return false
    }
  },
  [GROUP_GET_ALL]: async ({ commit }, { limit = 0, skip = 0 }) => {
    commit(GROUP_REQUEST, GROUP_GET_ALL)
    try {
      const resp = await api.get('group', {
        params: {
          filter: {
            limit,
            skip,
            include: [
              {
                relation: 'members'
              }
            ]
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_ALL_SUCCESS, {
          data: resp.data,
          totalCount: resp.headers['Content-Range']
        })
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_GET_JOINED]: async ({ commit }, { limit = 0, skip = 0 }) => {
    commit(GROUP_REQUEST, GROUP_GET_JOINED)
    try {
      const resp = await api.get('participant_group', {
        params: {
          filter: {
            where: {
              and: [
                {
                  participantID: store.getters.authId
                },
                {
                  created: {
                    neq: null
                  }
                },
                {
                  status: 'accepted'
                }
              ]
            },
            limit,
            skip,
            order: 'created ASC',
            include: [
              {
                relation: 'group',
                scope: {
                  include: [
                    {
                      relation: 'members',
                      scope: {
                        where: {
                          status: 'accepted'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_JOINED_SUCCESS, {
          data: resp.data,
          totalCount: resp.headers['x-total-count']
        })
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_GET_DETAILS]: async ({ commit }, groupId) => {
    commit(GROUP_REQUEST, GROUP_GET_DETAILS)
    try {
      const resp = await api.get(`group/${groupId}`)
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_DETAILS_SUCCESS, resp.data)
      } else {
        commit(GROUP_ERROR, 'Something went wrong herrre: ', resp.statusText)
      }
    } catch (err) {
      console.error(err)
      commit(GROUP_ERROR, `Something went wrongg: ${err}`)
    }
  },
  [GROUP_JOIN]: async ({ commit }, { groupId, participantId }) => {
    commit(GROUP_REQUEST, GROUP_JOIN)
    try {
      const resp = await api.post(`group/${groupId}/members`, {
        participantID: participantId
      })
      if (resp.status === 200 && resp.data) {
        commit(GROUP_JOIN_SUCCESS, resp.data)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_LEAVE]: async ({ commit, dispatch }, { groupId, memberId }) => {
    commit(GROUP_REQUEST, GROUP_LEAVE)
    try {
      const resp = await api.delete(`group/${groupId}/members/${memberId}`)
      if (resp.status === 204) {
        commit(GROUP_LEAVE_SUCCESS)
        dispatch(GROUP_GET_DETAILS, groupId)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_SEARCH]: async ({ commit }, { name, city, activityId }) => {
    commit(GROUP_REQUEST, GROUP_SEARCH)
    try {
      const resp = await api.get('group', {
        params: {
          filter: {
            where: {
              and: [
                {
                  private: false
                },
                {
                  name: {
                    ilike: name ? `%${name}%` : '%'
                  }
                },
                {
                  city: {
                    ilike: city ? `${city}` : '%'
                  }
                },
                {
                  activityID: activityId || {
                    gte: 0
                  }
                }
              ]
            },
            include: [
              {
                relation: 'members',
                scope: {
                  include: [
                    {
                      relation: 'participant',
                      scope: {
                        fields: ['firstName']
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(GROUP_SEARCH_SUCCESS, resp.data)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_GET_EVENTS]: async ({ commit }, { groupId }) => {
    commit(GROUP_REQUEST, GROUP_GET_EVENTS)
    try {
      const resp = await api.get(`group/${groupId}/events`, {
        params: {
          filter: {
            include: [
              {
                relation: 'dates',
                scope: {
                  order: 'startDateTime ASC',
                  where: {
                    startDateTime: {
                      gte: moment().valueOf()
                    }
                  },
                  include: [
                    {
                      relation: 'participant_dates',
                      scope: {
                        fields: ['attendance', 'participantID']
                      }
                    }
                  ]
                }
              },
              {
                relation: 'location',
                scope: {
                  fields: ['name', 'city']
                }
              }
            ]
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_EVENTS_SUCCESS, resp.data)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_GET_LEAGUES]: async ({ commit }, groupId) => {
    commit(GROUP_REQUEST, GROUP_GET_LEAGUES)
    try {
      const resp = await api.get(`group/${groupId}/leagues`)
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_LEAGUES_SUCCESS, resp.data)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_GET_TEAMS]: async ({ commit }, groupId) => {
    commit(GROUP_REQUEST, GROUP_GET_TEAMS)
    try {
      const resp = await api.get(`group/${groupId}/teams`)
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_TEAMS_SUCCESS, resp.data)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_DELETE]: async ({ commit }, groupId) => {
    commit(GROUP_REQUEST, GROUP_DELETE)
    try {
      await api.delete(`group/${groupId}/members`)
      const resp = await api.delete(`group/${groupId}`)
      if (resp.status === 200 && resp.data) {
        commit(GROUP_DELETE_SUCCESS)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_UPDATE_MEMBER]: async (
    { commit },
    { groupId, participantId, status }
  ) => {
    commit(GROUP_REQUEST, GROUP_UPDATE_MEMBER)
    try {
      const resp = await api.put(`group/${groupId}/members/${participantId}`, {
        status
      })
      if (resp.status === 200 && resp.data) {
        commit(GROUP_UPDATE_MEMBER_SUCCESS)
      } else {
        commit(GROUP_ERROR, `Something went wrong: ${resp.statusText}`)
      }
    } catch (err) {
      commit(GROUP_ERROR, `Something went wrong: ${err}`)
    }
  },
  [GROUP_GET_UPCOMING_EVENTDATES]: async (
    { commit },
    { groupId, skip, limit }
  ) => {
    commit(GROUP_REQUEST, GROUP_GET_UPCOMING_EVENTDATES)
    try {
      const resp = await api.get(
        `group/${groupId}/upcomingEvents?skip=${skip}&limit=${limit}`
      )
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_UPCOMING_EVENTDATES_SUCCESS, resp.data)
      } else {
        commit(
          GROUP_ERROR,
          `Something went wrong while getting upcoming group events: ${resp.statusText}`
        )
      }
    } catch (err) {
      commit(
        GROUP_ERROR,
        `Something went wrong while getting upcoming group events: ${err}`
      )
    }
  },
  [GROUP_GET_CREATED_OR_ADMIN]: async ({ commit }) => {
    commit(GROUP_REQUEST, GROUP_GET_CREATED_OR_ADMIN)
    try {
      const resp = await api.get(`group/createdOrAdmin`)
      if (resp.status === 200 && resp.data) {
        commit(GROUP_GET_CREATED_OR_ADMIN_SUCCESS, resp.data)
      } else {
        commit(
          GROUP_ERROR,
          `Something went wrong while getting created or admin events`
        )
      }
    } catch (err) {
      commit(
        GROUP_ERROR,
        `Something went wrong while getting created or admin events`
      )
    }
  }
}

const mutations = {
  [GROUP_REQUEST]: (state) => {
    state.loading = true
  },
  [GROUP_ERROR]: (state, errorMessage) => {
    state.loading = false
    state.error = errorMessage
  },

  [GROUP_CREATE_SUCCESS]: (state, group) => {
    state.createdGroup = group
    state.loading = false
  },

  [GROUP_UPDATE_SUCCESS]: (state, group) => {
    state.updatedGroup = group
    state.loading = false
  },

  [GROUP_UPLOAD_IMAGE_SUCCESS]: (state, imageUploadResponse) => {
    state.newImage = {
      container: isDefinedType(
        imageUploadResponse,
        'data.result.files.file.0.container',
        ['string', 'value']
      ),
      file: isDefinedType(
        imageUploadResponse,
        'data.result.files.file.0.name',
        ['string', 'value']
      )
    }
  },
  [GROUP_UPLOAD_IMAGE_ERROR]: (state, errorMessage) => {
    state.imageError = errorMessage
  },

  [GROUP_RESET_STATE]: (state) => {
    state.createdGroup = null
    state.error = null
    state.imageError = null
    state.joinedGroup = null
    state.loading = false
    state.newImage = null
    state.updatedGroup = null
    state.groupIsDeleted = false
  },

  [GROUP_GET_ALL_SUCCESS]: (state, { data, totalCount }) => {
    state.loading = false
    state.allGroups = {
      data: data
        .map((item) => ({
          ...item,
          imageUrl: getImageUrl(item.image)
        }))
        .sort((a, b) => b.acceptedMembers - a.acceptedMembers),
      totalCount
    }
  },

  [GROUP_GET_JOINED_SUCCESS]: (state, { data, totalCount }) => {
    state.loading = false
    state.groupsJoined = {
      data: data
        .map(({ group }) => ({
          ...(group && {
            ...group,
            ...(group.members ? { acceptedMembers: group.members.length } : {}),
            imageUrl: getImageUrl(group.image)
          })
        }))
        .filter((item) => Object.keys(item).length !== 0),
      totalCount
    }
  },

  [GROUP_GET_DETAILS_SUCCESS]: (state, data) => {
    state.loading = false
    state.memberUpdated = null
    state.groupDetails = data.hasAccess
      ? {
          activityId: data.activityID,
          admins: data.admins || [],
          city: data.city,
          // comments: formatImageInResponse(data.comments, 'avatar'),
          description: data.description,
          eventTypeId: data.event_typeID,
          imageUrl: getImageUrl(data.image),
          hasAccess: data.hasAccess,
          name: data.name,
          leagues: formatImageInResponse(data.leagues),
          members:
            data.members &&
            data.members.map((member) => ({
              avatarUrl: member.participant
                ? getImageUrl(member.participant.avatar, false)
                : null,
              id: member.id,
              name: `${member.firstName} ${member.lastName}`,
              firstName: member.firstName,
              lastName: member.lastName,
              participantId: member.participantID,
              status: member.status
            })),
          needsPermissionToAdd: data.needsPermissionToAdd,
          nextEvents:
            data.events &&
            data.events
              .filter(
                (event) =>
                  event.nextDate &&
                  moment(event.nextDate.startDateTime).isAfter(
                    moment().format()
                  )
              )
              .sort(
                (a, b) =>
                  moment(a.nextDate.startDateTime).valueOf() -
                  moment(b.nextDate.startDateTime).valueOf()
              )
              .map((event) => ({
                cost: event.cost,
                costFeeIncluded: costFeeIncluded(event.cost, event.fee),
                date:
                  event.nextDate &&
                  formatDate.short(event.nextDate.startDateTime),
                dateId: event.nextDate && event.nextDate.id,
                endTime:
                  event.nextDate && formatDate.time(event.nextDate.endDateTime),
                eventId: event.id,
                image: getImageUrl(event.image),
                location: event.location,
                maximumParticipants: event.maximumParticipants,
                name: event.name,
                participantCount: event.nextDate
                  ? event.nextDate.participantCount
                  : 0,
                startTime:
                  event.nextDate &&
                  formatDate.time(event.nextDate.startDateTime),
                securityHash: event.securityHash
              })),
          ownerId: data.ownerId,
          private: data.private,
          teams:
            data.teams &&
            data.teams.map((team) => ({
              id: team.id,
              name: team.name,
              lineup: team.lineups[0],
              image: getImageUrl(team.image)
            })),
          participantTeams:
            data.teams &&
            data.teams
              .map((team) => {
                const isInTeam =
                  !!team.lineups &&
                  !!team.lineups.length &&
                  !!team.lineups[0].members.includes(
                    parseInt(store.getters.authId)
                  )
                return isInTeam
                  ? { ...team, imageUrl: getImageUrl(team.image) }
                  : null
              })
              .filter((team) => team)
        }
      : {
          imageUrl: getImageUrl(data.image),
          ...data
        }
  },

  [GROUP_JOIN_SUCCESS]: (state, resp) => {
    state.loading = false
    state.joinedGroup = resp
  },

  [GROUP_LEAVE_SUCCESS]: (state) => {
    state.loading = false
  },

  [GROUP_SEARCH_SUCCESS]: (state, results) => {
    state.loading = false
    state.search = {
      count: results.length,
      results
    }
  },
  [GROUP_SEARCH_CLEAR]: (state) => {
    state.search = {
      count: null,
      results: []
    }
  },

  [GROUP_GET_UPCOMING_EVENTDATES_SUCCESS]: (state, data) => {
    state.loading = false
    let eventdates = []
    if (data && data.length) {
      eventdates = data.map((eventdate) => ({
        ...eventdate,
        image:
          eventdate.image && Object.keys(eventdate.image).length
            ? getImageUrl(eventdate.image)
            : null,
        costFeeIncluded: costFeeIncluded(eventdate.cost, eventdate.fee),
        date: formatDate.short(eventdate.startDateTime),
        endTime: formatDate.time(eventdate.endDateTime),
        startTime: formatDate.time(eventdate.startDateTime),
        securityHash: eventdate.securityHash
      }))
    }
    state.upcomingEvents = eventdates
  },

  [GROUP_GET_LEAGUES_SUCCESS]: (state, data) => {
    state.loading = false
    state.leagues = data
  },

  [GROUP_GET_TEAMS_SUCCESS]: (state, data) => {
    state.loading = false
    state.teams = data
  },

  [GROUP_DELETE_SUCCESS]: (state) => {
    state.loading = false
    state.groupIsDeleted = true
  },
  [GROUP_UPDATE_MEMBER_SUCCESS]: (state) => {
    state.loading = false
    state.memberUpdated = true
  },
  [GROUP_GET_CREATED_OR_ADMIN_SUCCESS]: (state, data) => {
    state.loading = false
    state.groupsCreatedOrAdmin = data
  }
}

export const getImageUrl = (imageObj, placeholder = true) => {
  if (imageObj) {
    return `${config.baseUrl}/api/attachments/${imageObj.container}/download/${imageObj.file}`
  } else {
    return placeholder ? noImage : null
  }
}

export const formatDate = {
  short: (date) => {
    return `${DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}`
  },
  long: (date) => {
    return `${DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE)}`
  },
  time: (date) => {
    return `${DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE)}`
  }
}

const formatImageInResponse = (array) => {
  return Array.isArray(array)
    ? array.map((item) => ({
        ...item,
        ...(item.image && { image: getImageUrl(item.image) }),
        ...(item.createdBy &&
          item.createdBy.avatar && {
            image: getImageUrl(item.createdBy.avatar)
          })
      }))
    : []
}

const costFeeIncluded = (cost, fee) => {
  const c = isNaN(cost) || cost === null ? '0' : cost
  const f = isNaN(fee) || fee === null ? '0' : fee
  return (parseFloat(c) + parseFloat(f)).toFixed(2)
}

export default {
  state,
  getters,
  actions,
  mutations
}
