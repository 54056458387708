import {
  PARTICIPANT_ADD_ACTIVITY,
  PARTICIPANT_AVATAR_UPLOAD_SUCCESS,
  PARTICIPANT_AVATAR_UPLOAD,
  PARTICIPANT_CLEAR,
  PARTICIPANT_CREATE_SUCCESS,
  PARTICIPANT_CREATE,
  PARTICIPANT_ERROR,
  PARTICIPANT_GET_BY_IDS_SUCCESS,
  PARTICIPANT_GET_BY_IDS,
  PARTICIPANT_OWN_SUCCESS,
  PARTICIPANT_OWN,
  PARTICIPANT_PASSWORD_RESET_REQUEST,
  PARTICIPANT_PASSWORD_RESET,
  PARTICIPANT_REQUEST,
  PARTICIPANT_SEARCH_CLEAR,
  PARTICIPANT_SEARCH_SUCCESS,
  PARTICIPANT_SEARCH,
  PARTICIPANT_STORE_IP,
  PARTICIPANT_STORE_IP_SUCCESS,
  PARTICIPANT_UPDATE_ACTIVITY,
  PARTICIPANT_UPDATE_BANKACCOUNT_SUCCESS,
  PARTICIPANT_UPDATE_BANKACCOUNT,
  PARTICIPANT_UPDATE_PHONENUMBER_SUCCESS,
  PARTICIPANT_UPDATE_PHONENUMBER,
  PARTICIPANT_UPDATE_SUCCESS,
  PARTICIPANT_UPDATE,
  PARTICIPANT_VIEW_SUCCESS,
  PARTICIPANT_VIEW,
  PARTICIPANT_GET_CREDITCARD_ON_EVENT,
  PARTICIPANT_GET_CREDITCARD_ON_EVENT_SUCCESS,
  PARTICIPANT_GET_CREDITCARDS,
  PARTICIPANT_GET_CREDITCARDS_SUCCESS,
  PARTICIPANT_GET_GROUPS_OWNED,
  PARTICIPANT_GET_GROUPS_OWNED_SUCCESS
} from '../actions/participant'
import {
  PARTICIPANT_ACTIVITY_CREATE,
  PARTICIPANT_ACTIVITY_DELETE,
  PARTICIPANT_ACTIVITY_FIND_ONE
} from '../actions/participant_activity'
import { ACTIVITY_LIST } from '../actions/activity'
import '@/assets/js/photoRotationAdjust'
import Vue from 'vue'
import { AUTH_LOGOUT } from '../actions/auth'
import store from '../../store'
import config from '@/utils/config'
import api, { setAuthToken } from '@/utils/api'
import _ from 'lodash'
import isDefinedType from 'is-defined-type'
import axios from 'axios'

const state = {
  status: '',
  ownProfile: {},
  profile: {},
  newAvatar: null,
  searchResults: [],
  participantsById: [],
  creditcards: {},
  purchasedCreditcards: {},
  ip: undefined,
  ownGroups: []
}

const getters = {
  getParticipantProfile: (state) => state.ownProfile,
  userIsInOppTestGroup: (state) => {
    return (
      config.oppTestGroup
        ?.split(',')
        .map((id) => parseInt(id))
        .includes(state.ownProfile.id) || false
    )
  }
}

const actions = {
  [PARTICIPANT_OWN]: ({ commit, dispatch }) => {
    commit(PARTICIPANT_REQUEST)
    api
      .get(`participant/${store.getters.authId}`, {
        params: {
          filter: {
            include: [
              {
                relation: 'activity_types'
              },
              {
                relation: 'merchant'
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(PARTICIPANT_OWN_SUCCESS, resp.data)
      })
      .catch(() => {
        commit(PARTICIPANT_ERROR)
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT)
      })
  },
  [PARTICIPANT_VIEW]: ({ commit }, id) => {
    commit(PARTICIPANT_REQUEST)
    api
      .get(`participant/${id}`, {
        params: {
          filter: {
            fields: [
              'name',
              'avatar',
              'avatarUrl',
              'id',
              'firstName',
              'lastName'
            ],
            include: [
              {
                relation: 'activity_types'
              }
            ]
          }
        }
      })
      .then((resp) => {
        commit(PARTICIPANT_VIEW_SUCCESS, resp.data)
      })
      .catch(() => {
        commit(PARTICIPANT_ERROR)
      })
  },
  [PARTICIPANT_CREATE]: ({ commit }, item) => {
    commit(PARTICIPANT_REQUEST)
    const queryString = Object.keys(item.query)
      .map((key) => {
        return `${key}=${item.query[key]}`
      })
      .join('&')

    return new Promise((resolve, reject) => {
      api
        .post(`participant${queryString ? `?${queryString}` : ''}`, {
          firstName: item.participant.firstName,
          lastName: item.participant.lastName,
          city: item.participant.city ? item.participant.city : null,
          phoneNumber: item.participant.phoneNumber
            ? item.participant.phoneNumber
            : null,
          gender: item.participant.gender,
          yearOfBirth: item.participant.yearOfBirth,
          postalCode: item.participant.postalCode
            ? item.participant.postalCode.replace(/\s/g, '')
            : item.participant.postalCode,
          newsletterGeneral: item.participant.newsletterGeneral,
          newsletterActivities: item.participant.newsletterActivities,
          newsletterActivitiesWeekly:
            item.participant.newsletterActivitiesWeekly,
          newsletterActivitiesMonthly:
            item.participant.newsletterActivitiesMonthly,
          username: item.participant.email,
          password: item.participant.password,
          email: item.participant.email,
          rangeChoice: item.participant.rangeChoice,
          coordinates: item.participant.coordinates,
          commentsUpdate: item.participant.commentsUpdate,
          athlete: item.participant.athlete,
          activities: item.activities,
          query: item.query
        })
        .then((resp) => {
          commit(PARTICIPANT_CREATE_SUCCESS, resp)
          resolve(resp)
        })
        .catch((err) => {
          commit(PARTICIPANT_ERROR)
          reject(err)
        })
    })
  },
  [PARTICIPANT_UPDATE]: async ({ commit, dispatch }, item) => {
    commit(PARTICIPANT_REQUEST)
    await dispatch(PARTICIPANT_AVATAR_UPLOAD, item.participant.newAvatar)
    return new Promise((resolve, reject) => {
      // Build object with updates
      let participantObject = {
        firstName: item.participant.firstName,
        lastName: item.participant.lastName,
        gender: item.participant.gender,
        yearOfBirth: item.participant.yearOfBirth,
        phoneNumber: item.participant.phoneNumber,
        postalCode: item.participant.postalCode
          ? item.participant.postalCode.replace(/\s/g, '')
          : item.participant.postalCode,
        newsletterGeneral: item.participant.newsletterGeneral,
        newsletterActivities: item.participant.newsletterActivities,
        newsletterActivitiesMonthly:
          item.participant.newsletterActivitiesMonthly,
        newsletterActivitiesWeekly: item.participant.newsletterActivitiesWeekly,
        username: item.participant.email,
        password: item.participant.password ? item.participant.password : null,
        email: item.participant.email,
        avatar: state.newAvatar,
        city: item.participant.city,
        rangeChoice: item.participant.rangeChoice,
        coordinates: item.participant.coordinates,
        commentsUpdate: item.participant.commentsUpdate,
        bankAccountNumber: item.participant.bankAccountNumber
      }
      // Remove avatar if null
      if (state.newAvatar === null) {
        participantObject = _.omit(participantObject, ['avatar'])
      }
      api
        .patch(`participant/${store.getters.authId}`, participantObject)
        .then((resp) => {
          dispatch(PARTICIPANT_UPDATE_ACTIVITY, {
            participantID: resp.data.id,
            activities: item.activities
          })
            .then(() => {
              commit(PARTICIPANT_UPDATE_SUCCESS, resp)
              resolve(resp)
            })
            .catch((err) => {
              reject(err)
            })
        })
        .catch((err) => {
          commit(PARTICIPANT_ERROR)
          reject(err)
        })
    })
  },
  [PARTICIPANT_ADD_ACTIVITY]: ({ dispatch }, item) => {
    for (const property in item.activities) {
      if (!item.activities.hasOwnProperty(property)) continue // eslint-disable-line no-prototype-builtins
      const activityID = item.activities[property]
      dispatch(PARTICIPANT_ACTIVITY_CREATE, {
        activityID: activityID,
        participantID: item.participantID
      })
    }
  },
  [PARTICIPANT_UPDATE_ACTIVITY]: ({ dispatch }, item) => {
    const participantID = item.participantID
    const selectedActivityIDs = item.activities

    return new Promise((resolve) => {
      dispatch(ACTIVITY_LIST).then(async () => {
        for (const property in store.state.activity.activityKeyValueList) {
          if (
            !store.state.activity.activityKeyValueList.hasOwnProperty(property) // eslint-disable-line no-prototype-builtins
          ) {
            continue
          }
          const isLastItem =
            parseInt(property) + 1 ===
            _.size(store.state.activity.activityKeyValueList)
          const activityID =
            store.state.activity.activityKeyValueList[property].key
          const responseFindOne = await dispatch(
            PARTICIPANT_ACTIVITY_FIND_ONE,
            { activityID: activityID, participantID: participantID }
          )
          if (_.includes(selectedActivityIDs, activityID)) {
            if (responseFindOne.data === undefined) {
              const responseCreate = await dispatch(
                PARTICIPANT_ACTIVITY_CREATE,
                { activityID: activityID, participantID: participantID }
              )
              if (responseCreate && isLastItem) {
                resolve(true)
              }
            } else {
              if (isLastItem) {
                resolve(true)
              }
            }
          } else {
            if (responseFindOne.data !== undefined) {
              const responseDelete = await dispatch(
                PARTICIPANT_ACTIVITY_DELETE,
                responseFindOne.data.id
              )
              if (responseDelete && isLastItem) {
                resolve(true)
              }
            } else {
              if (isLastItem) {
                resolve(true)
              }
            }
          }
        }
      })
    })
  },
  async [PARTICIPANT_AVATAR_UPLOAD]({ commit }, newAvatar) {
    let avatarUploadResponse = null
    try {
      if (newAvatar != null) {
        const adjustBlob = await window.photoRotationAdjust(newAvatar)
        const formData = new FormData()
        formData.append('file', adjustBlob)
        avatarUploadResponse = await api.post(
          `attachments/${config.storageContainer}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        commit(PARTICIPANT_AVATAR_UPLOAD_SUCCESS, avatarUploadResponse)
        return true
      } else {
        return true
      }
    } catch (e) {
      return true
    }
  },
  // WILL THIS WORK??
  // eslint-disable-next-line no-unused-vars
  async [PARTICIPANT_PASSWORD_RESET_REQUEST]({ dispatch }, email) {
    return api.post('/participant/reset', { email: email })
  },
  // eslint-disable-next-line no-unused-vars
  async [PARTICIPANT_PASSWORD_RESET]({ dispatch }, resetData) {
    if (resetData.resetToken !== undefined) {
      setAuthToken(resetData.resetToken)
    }
    return api.post('/participant/reset-password', {
      newPassword: resetData.newPassword
    })
  },
  [PARTICIPANT_SEARCH]: async ({ commit }, { query, groupId }) => {
    commit(PARTICIPANT_REQUEST)
    try {
      const resp = await api.get('participant/searchInGroup', {
        params: {
          query,
          groupId
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_SEARCH_SUCCESS, resp.data)
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR)
    }
  },
  [PARTICIPANT_GET_BY_IDS]: async ({ commit }, { ids }) => {
    commit(PARTICIPANT_REQUEST)
    try {
      const resp = await api.get('participant', {
        params: {
          filter: {
            where: {
              id: {
                inq: ids
              }
            }
          }
        }
      })
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_GET_BY_IDS_SUCCESS, resp.data)
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR)
    }
  },
  [PARTICIPANT_UPDATE_BANKACCOUNT]: async (
    { commit },
    { bankAccountNumber, bankAccountName }
  ) => {
    commit(PARTICIPANT_REQUEST)
    try {
      const resp = await api.patch(
        `participant/${parseInt(store.getters.authId)}`,
        {
          ...(bankAccountNumber ? { bankAccountNumber } : null),
          ...(bankAccountName ? { bankAccountName } : null)
        }
      )
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_UPDATE_BANKACCOUNT_SUCCESS, {
          bankAccountName,
          bankAccountNumber
        })
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR)
    }
  },
  [PARTICIPANT_UPDATE_PHONENUMBER]: async ({ commit }, { phoneNumber }) => {
    commit(PARTICIPANT_REQUEST)
    try {
      const resp = await api.patch(
        `participant/${parseInt(store.getters.authId)}`,
        {
          phoneNumber
        }
      )
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_UPDATE_PHONENUMBER_SUCCESS)
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR)
    }
  },
  [PARTICIPANT_STORE_IP]: async ({ commit }) => {
    try {
      const getIp = await axios.get('https://jsonip.com/')
      if (getIp.status === 200) {
        commit(PARTICIPANT_STORE_IP_SUCCESS, getIp.data.ip)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR, e)
    }
  },
  [PARTICIPANT_GET_CREDITCARD_ON_EVENT]: async ({ commit }, { eventId }) => {
    commit(PARTICIPANT_REQUEST, {
      action: PARTICIPANT_GET_CREDITCARD_ON_EVENT,
      payload: { eventId }
    })
    try {
      const resp = await api.get(`participant/creditcard/event/${eventId}`)
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_GET_CREDITCARD_ON_EVENT_SUCCESS, {
          card: resp.data[0],
          eventId
        })
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR)
    }
  },
  [PARTICIPANT_GET_CREDITCARDS]: async ({ commit }) => {
    commit(PARTICIPANT_REQUEST, {
      action: PARTICIPANT_GET_CREDITCARDS
    })
    try {
      const resp = await api.get(`participant/creditcards_bought`)
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_GET_CREDITCARDS_SUCCESS, { data: resp.data })
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR, e)
    }
  },
  [PARTICIPANT_GET_GROUPS_OWNED]: async ({ commit }, { participantId }) => {
    commit(PARTICIPANT_REQUEST, {
      action: PARTICIPANT_GET_GROUPS_OWNED
    })
    try {
      const resp = await api.get(`participant/${participantId}/ownGroups`)
      if (resp.status === 200 && resp.data) {
        commit(PARTICIPANT_GET_GROUPS_OWNED_SUCCESS, { data: resp.data })
      } else {
        commit(PARTICIPANT_ERROR)
      }
    } catch (e) {
      commit(PARTICIPANT_ERROR, e)
    }
  }
}

const mutations = {
  [PARTICIPANT_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [PARTICIPANT_OWN_SUCCESS]: (state, resp) => {
    state.status = 'success'
    resp.avatarUrl =
      resp.avatarUrl !== null && resp.avatarUrl !== undefined
        ? config.baseUrl + resp.avatarUrl
        : null
    resp.activities = resp.activity_types
    Vue.set(state, 'ownProfile', resp)
  },
  [PARTICIPANT_VIEW_SUCCESS]: (state, resp) => {
    state.status = 'success'
    resp.avatar =
      resp.avatarUrl !== null ? config.baseUrl + resp.avatarUrl : null
    Vue.set(state, 'profile', resp)
  },
  [PARTICIPANT_CREATE_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [PARTICIPANT_UPDATE_SUCCESS]: (state) => {
    state.status = 'success'
    state.newAvatar = null
  },
  [PARTICIPANT_ERROR]: (state) => {
    state.status = 'error'
  },
  [PARTICIPANT_AVATAR_UPLOAD_SUCCESS]: (state, resp) => {
    state.newAvatar = {
      container: isDefinedType(resp, 'data.result.files.file.0.container', [
        'string',
        'value'
      ]),
      file: isDefinedType(resp, 'data.result.files.file.0.name', [
        'string',
        'value'
      ])
    }
  },
  [AUTH_LOGOUT]: (state) => {
    state.ownProfile = {}
  },
  [PARTICIPANT_CLEAR]: (state) => {
    state.ownProfile = {}
  },
  [PARTICIPANT_SEARCH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.searchResults = resp
  },
  [PARTICIPANT_SEARCH_CLEAR]: (state) => {
    state.searchResults = []
  },
  [PARTICIPANT_GET_BY_IDS_SUCCESS]: (state, resp) => {
    state.participantsById = resp
  },
  [PARTICIPANT_UPDATE_BANKACCOUNT_SUCCESS]: (
    state,
    { bankAccountName, bankAccountNumber }
  ) => {
    state.status = 'success'
    state.ownProfile = {
      ...state.ownProfile,
      bankAccountName,
      bankAccountNumber
    }
  },
  [PARTICIPANT_UPDATE_PHONENUMBER_SUCCESS]: (state) => {
    state.status = 'success'
  },
  [PARTICIPANT_STORE_IP_SUCCESS]: (state, ip) => {
    state.ip = ip
  },
  [PARTICIPANT_GET_CREDITCARD_ON_EVENT_SUCCESS]: (state, { card, eventId }) => {
    state.status = 'success'
    state.creditcards = {
      ...state.creditcards,
      [eventId]: card
    }
  },
  [PARTICIPANT_GET_CREDITCARDS_SUCCESS]: (state, { data }) => {
    state.status = 'success'
    state.purchasedCreditcards = data
  },
  [PARTICIPANT_GET_GROUPS_OWNED_SUCCESS]: (state, { data }) => {
    state.status = 'success'
    state.ownGroups = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
